.ymaps-2-1-77-ground-pane {
  -webkit-filter: grayscale(100%);
}

#mapcontaier {
  width: 100%;
  height: 100vh;
}

.placemark {
  padding: 10px;
  background: white;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 24px rgba(0, 0, 0, 0.08);
  border-radius: 100%;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
}
.placemark .icon {
  font-size: 40px;
}
