.news-feed-card-icons {
    display: flex;
    align-items: center;
}

.news-feed-card-icons-wraper {
    display: flex;
}

.news-feed-card-icons-wraper .news-feed-card-icons {
    margin-right: 12px;
}
