.new-feed-separator {
  background-color: #ebedf0;
  width: 100%;
  height: 10px;
}

.image-holder {
  height: 320px;
  background-position: center;
  background-size: cover;
}

.buttons-wraper {
  background: white;
}

.buttons-wraper button + button {
  margin-left: 12px;
}

.float-button {
  position: fixed;
  bottom: 66px;
  right: 20px;
  border-radius: 100%;
  padding: 0px 7px;
}
